import React from 'react';
import Style from './About.module.scss';
import Terminal from "./Terminal";
import { Box, Link } from "@mui/material";
import { info } from "../../info/Info";


export default function About({ innerRef }) {

    function aboutMeText() {
        return <>
            <p>
                🌟 Hello! I’m a <b>Research Scientist</b> at Meta (GenAI org.), where I dive into the fascinating world of diffusion models and large-scale training. These models enable the generation of 3D assets and media editing capabilities. 🎨✨ Before, I had interned at Microsoft Research Lab and Huawei Noah's Ark.
            </p>

            <p>🚀 <b>Collaborations:</b> I’m fortunate to work alongside —<Link href="https://www.robots.ox.ac.uk/~vedaldi/">Andrea Vedaldi</Link>  and  <Link href="https://www.linkedin.com/in/natalia-neverova-14066133/?originalSubdomain=uk">Natalia Neverova</Link>  and the rest of the 3D team. Together, we’re pushing the boundaries of what’s possible in computer vision and graphics.</p>
            <p>🎓 <b>My Mentors:</b> During my academic journey, I’ve been mentored by some incredible professors:</p>
            <ul>
                <li> - <Link href="https://jkokkin.github.io/"> Iasonas Kokkinos (UCL)</Link> </li>
                <li> - <Link href="https://www.linkedin.com/in/stamatis-lefkimmiatis-37855930/"> Stamatis Lefkimmiatis (SkolTech)</Link> </li>
                <li> - <Link href="https://slp-ntua.github.io/potam/"> Alexandros Potamianos (NTUA) </Link> </li>
            </ul>


            <p>
                👩‍🎓 <b>Supervising Students:</b> As a mentor, I’ve had the honor of guiding brilliant students:
            </p>
            <ul>
                <li> - <Link href="https://junlinhan.github.io/">Junlin Han</Link>: Ph.D with Oxford </li>
                <li> - <Link href="https://www.linkedin.com/in/shalini-maiti-a76a2b86/?originalSubdomain=uk">Shalini Maiti</Link>: Ph.D with UCL </li>
                <li> - <Link href="https://erictuanle.com/">Eric Tuan Le</Link>: Intern at Meta (UCL) </li>
                <li> - <Link href="https://lukemelas.github.io/">Luke Melas-Kyriazi</Link>: Intern at Meta (Oxford) </li>
                <li> - <Link href="https://yufan-ren.com/">Yufan Ren</Link>: Intern at Meta (EPFL) </li>
                <li> - <Link href="https://ktertikas.github.io/">Konstantinos Tertikas</Link>: Intern at Meta (UOA) </li>
                <li> - <Link href="https://morrisalp.github.io/">Morris Alper</Link>: Intern at Meta (Tel Aviv University) </li>
            </ul>
        </>;
    }



    return (
        <Box ref={innerRef} display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'3rem'} id={'about'}>
            <Terminal text={aboutMeText()} />
        </Box>
    )
}