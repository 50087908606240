import React from 'react';
import { Box, Stack } from "@mui/material";
import PublicationCard from './PublicationCard';
import publications from '../../info/publications';

export default function Portfolio({ innerRef }) {
    return (
        <Box id={'portfolio'} ref={innerRef} display={'flex'} justifyContent={'center'} p={2}>
            <Stack spacing={2}>
                {publications.map((publication, index) => (
                    <PublicationCard
                        key={index}
                        image={publication.image}
                        video={publication.video}
                        title={publication.title}
                        authors={publication.authors}
                        projectPage={publication.projectPage}
                        arxivLink={publication.arxivLink}
                        conference={publication.conference}
                        mediaLink={publication.mediaLink}
                        backgroundColor={publication.backgroundColor}
                    />
                ))}
            </Stack>
        </Box>
    );
}
