import React from 'react';
import { Box, Card, CardContent, CardMedia, Typography, Link, Grid, Stack } from "@mui/material";

function PublicationCard({ image, video, title, authors, projectPage, arxivLink, conference, mediaLink, backgroundColor }) {
    const boldName = "Filippos Kokkinos";
    console.log('Background Color:', backgroundColor);  // Log to verify


    return (
        <Card sx={{ 
            maxWidth: 800, 
            p: 2,
            backgroundImage: backgroundColor,  // Use provided backgroundColor or default gradient
            backgroundSize: 'cover',
        }}>
            <Grid container spacing={2}>
                <Grid item xs={4} justifyContent={'center'} alignContent={'center'}>
                    {video ? (
                        <CardMedia
                            component="video"
                            src={video}
                            sx={{ cursor: 'pointer', objectFit: 'cover' }}
                            title={title}
                            onClick={() => window.open(mediaLink, "_blank")}
                            autoPlay
                            muted
                            loop
                        />
                    ) : (
                        <CardMedia
                            component="img"
                            src={image}  // Use a placeholder image for debugging
                            sx={{ cursor: 'pointer', objectFit: 'cover' }}
                            title={title}
                            onClick={() => window.open(mediaLink, "_blank")}
                        />
                    )}
                </Grid>
                <Grid item xs={8}>
                    <CardContent sx={{ p: 2 }}>
                        <Typography gutterBottom variant="body1" component="div">
                            <b>{title}</b>
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {authors.map((author, index) => (
                                <span key={index} style={{ fontWeight: author.includes(boldName) ? 'bold' : 'normal' }}>
                                    {author}{index < authors.length - 1 && ', '}
                                </span>
                            ))}
                        </Typography>
                        <Stack direction="row" spacing={1} mt={2}>
                            <Typography variant="body1" color="text.secondary" mt={1}>
                                <Link href={projectPage} target="_blank" rel="noopener">project page</Link> / <Link href={arxivLink} target="_blank" rel="noopener">arXiv</Link>
                            </Typography>
                        </Stack>
                        <Typography variant="body1" color="text.secondary" mt={1}>
                            {conference}
                        </Typography>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
}

export default PublicationCard;
