import im3d from '../img/im3d.mp4';
import dreamcraft from '../img/dreamcraft.jpg';
import vfusion3d from '../img/vfusion3d.jpg';
import real_humans from '../img/real_humans.mp4';
import polynomials_babiloni from '../img/polynomials_babiloni.jpg';
import thesis from '../img/thesis.jpg';
import makeavideo3d from '../img/makeavideo3d.mp4';
import appensetup from '../img/appen-setup.jpg';
import babiloni from '../img/babiloni.jpg';
import tothepoint from '../img/tothepoint.jpg';
import cvpr2021 from '../img/cvpr2021.jpg';
import microscopy from '../img/microscopy.jpg';
import pafu from '../img/pafu.jpg';
import tip from '../img/tip.jpg';
import cvpr_burst from '../img/cvpr_burst.jpg';
import ntua from '../img/ntua.jpg';
import eacl from '../img/eacl.jpg';
import emu3d from '../img/emu3d.mp4';
import meta_3d from '../img/meta_3d.mp4';
import flex3d from '../img/flex3d.mp4';

 const publications = [
    {
        image: '',  // replace with your image path
        video: flex3d,  // if you have a video, place its path here
        title: 'Flex3D: Feed-Forward 3D Generation With Flexible Reconstruction Model And Input View Curation',
        authors: ['Junlin Han', 'Jianyuan Wang', 'Andrea Vedaldi', 'Philip Torr', 'Filippos Kokkinos'],
        projectPage: 'https://junlinhan.github.io/projects/flex3d/',
        arxivLink: 'https://arxiv.org/abs/2410.00890',
        conference: 'arxiv, 2024',
        mediaLink: 'https://junlinhan.github.io/projects/flex3d/',  // This link will be used when the media (image/video) is clicked
        // backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);',
    },
    {
        image: '',  // replace with your image path
        video: meta_3d,  // if you have a video, place its path here
        title: 'Meta 3D Gen',
        authors: ['Raphael Bensadoun*', 'Tom Monnier*', 'Yanir Kleiman*', 'Filippos Kokkinos', 'Yawar Siddiqui', 'Mahendra Kariya', 'Omri Harosh', 'Roman Shapovalov', 'Benjamin Graham', 'Emilien Garreau', 'Animesh Karnewar', 'Ang Cao', 'Idan Azuri', 'Iurii Makarov', 'Eric-Tuan Le', 'Antoine Toisoul', 'David Novotny',
            'Oran Gafni', 'Natalia Neverova', 'Andrea Vedaldi'],
        projectPage: 'https://ai.meta.com/research/publications/meta-3d-gen/?utm_source=threads&utm_medium=organic_social&utm_content=carousel&utm_campaign=research',
        arxivLink: 'https://arxiv.org/abs/2407.02599',
        conference: 'arxiv, 2024',
        mediaLink: 'https://ai.meta.com/research/publications/meta-3d-gen/?utm_source=threads&utm_medium=organic_social&utm_content=carousel&utm_campaign=research',  // This link will be used when the media (image/video) is clicked
        backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);',
    },
    {
        image: '',  // replace with your image path
        video: emu3d,  // if you have a video, place its path here
        title: 'Meta 3D AssetGen: Text-to-Mesh Generation with High-Quality Geometry, Texture, and PBR Materials',
        authors: ['Yawar Siddiqui*', 'Tom Monnier*', 'Filippos Kokkinos*', 'Mahendra Kariya', 'Yanir Kleiman', 'Emilien Garreau', 'Oran Gafni', 'Natalia Neverova', 'Andrea Vedaldi', 'David Novotny*', 'Roman Shapovalov*'],
        projectPage: 'https://assetgen.github.io/',
        arxivLink: 'https://arxiv.org/abs/2407.02445',
        conference: 'Neurips, 2024',
        mediaLink: 'https://assetgen.github.io/',  // This link will be used when the media (image/video) is clicked
        backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);',
    },
    {
        image: dreamcraft,  // replace with your image path
        video: '',  // if you have a video, place its path here
        title: 'DreamCraft: Text-Guided Generation of Functional 3D Environments in Minecraft',
        authors: ['Sam Earle', 'Filippos Kokkinos', 'Yuhe Nie', 'Julian Togelius', 'Roberta Raileanu'],
        projectPage: 'https://x.com/Smearle_RH/status/1787984443421974771',
        arxivLink: 'https://arxiv.org/abs/2404.15538',
        conference: 'FDG, 2024',
        mediaLink: 'https://arxiv.org/abs/2404.15538',  // This link will be used when the media (image/video) is clicked
        // backgroundColor: '#8EC5FC',
    },
    {
        image: vfusion3d,  // replace with your image path
        video: '',  // if you have a video, place its path here
        title: 'Vfusion3d: Learning scalable 3d generative models from video diffusion models',
        authors: ['Junlin Han', 'Filippos Kokkinos', 'Philip Torr'],
        projectPage: 'https://junlinhan.github.io/projects/vfusion3d.html',
        arxivLink: 'https://arxiv.org/abs/2403.12034',
        conference: 'ECCV, 2024',
        mediaLink: 'https://junlinhan.github.io/projects/vfusion3d.html',  // This link will be used when the media (image/video) is clicked
        backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);',
    },
    {
        image: '',  // replace with your image path
        video: im3d,  // if you have a video, place its path here
        title: 'IM-3D: Iterative Multiview Diffusion and Reconstruction for High-Quality 3D Generation',
        authors: ['Luke Melas-Kyriazi*', 'Iro Laina', 'Christian Rupprecht', 'Natalia Neverova', 'Andrea Vedaldi', 'Oran Gafni', 'Filippos Kokkinos*'],
        projectPage: 'https://lukemelas.github.io/IM-3D/',
        arxivLink: 'https://arxiv.org/abs/2402.08682',
        conference: 'ICML, 2024',
        mediaLink: 'https://lukemelas.github.io/IM-3D/',  // This link will be used when the media (image/video) is clicked
        backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);'
        
    },
    {
        image: '',  // replace with your image path
        video: real_humans,  // if you have a video, place its path here
        title: 'Real-time volumetric rendering of dynamic humans',
        authors: ['Ignacio Rocco', 'Iurii Makarov', 'Filippos Kokkinos', 'David Novotny', 'Benjamin Graham', 'Natalia Neverova', 'Andrea Vedaldi'],
        projectPage: 'https://real-time-humans.github.io/',
        arxivLink: 'https://arxiv.org/abs/2303.11898',
        conference: 'arXiv, 2023',
        mediaLink: 'https://real-time-humans.github.io/',  // This link will be used when the media (image/video) is clicked
        // backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);'
        
    },
    {
        image: polynomials_babiloni,  // replace with your image path
        video: '',  // if you have a video, place its path here
        title: 'Linear Complexity Self-Attention With 3rd Order Polynomials',
        authors: ['Francesca Babiloni', 'Ioannis Marras', 'Jiankang Deng', 'Filippos Kokkinos', 'Matteo Maggioni', 'Grigorios Chrysos', 'Philip Torr', 'Stefanos Zafeiriou'],
        projectPage: 'https://ieeexplore.ieee.org/abstract/document/10076897',
        arxivLink: 'https://ieeexplore.ieee.org/abstract/document/10076897',
        conference: 'PAMI, 2023',
        mediaLink: 'https://ieeexplore.ieee.org/abstract/document/10076897',  // This link will be used when the media (image/video) is clicked
        // backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);'
    },
    {
        image: thesis,  // replace with your image path
        video: '',  // if you have a video, place its path here
        title: 'Deep structured layers for instance-level optimization in 2D and 3D vision',
        authors: ['Filippos Kokkinos'],
        projectPage: 'https://discovery.ucl.ac.uk/id/eprint/10164015/',
        arxivLink: 'https://discovery.ucl.ac.uk/id/eprint/10164015/1/Kokkinos_Main.pdf',
        conference: 'Ph.D Thesis',
        mediaLink: 'https://discovery.ucl.ac.uk/id/eprint/10164015/',  // This link will be used when the media (image/video) is clicked
        // backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);'
    },
    {
        image: '',  // replace with your image path
        video: makeavideo3d,  // if you have a video, place its path here
        title: 'Text-To-4D Dynamic Scene Generation',
        authors: ['Uriel Singer*', 'Shelly Sheynin*', 'Adam Polyak*','Oron Ashual','Iurii Makarov','Filippos Kokkinos','Naman Goyal','Andrea Vedaldi','Devi Parikh','Justin Johnson','Yaniv Taigman'],
        projectPage: 'https://make-a-video3d.github.io/',
        arxivLink: 'https://arxiv.org/abs/2301.11280',
        conference: 'ICML, 2023',
        mediaLink: 'https://make-a-video3d.github.io/',  // This link will be used when the media (image/video) is clicked
        backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);'
    },
    {
        image: appensetup,  // replace with your image path
        video: '',  // if you have a video, place its path here
        title: 'Replay: Multi-modal Multi-view Acted Videos for Casual Holography',
        authors: ['Roman Shapovalov', 'Yanir Kleiman', 'Ignacio Rocco', 'David Novotny', 'Andrea Vedaldi', 'Changan Chen', 'Filippos Kokkinos', 'Ben Graham', 'Natalia Neverova'],
        projectPage: 'https://replay-dataset.github.io/',
        arxivLink: 'https://arxiv.org/abs/2307.12067',
        conference: 'ICCV, 2023',
        mediaLink: 'https://replay-dataset.github.io/',  // This link will be used when the media (image/video) is clicked
        // backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);'
    },
    {
        image: babiloni,  // replace with your image path
        video: '',  // if you have a video, place its path here
        title: 'Poly-NL: Linear Complexity Non-local Layers with Polynomials',
        authors: ['Francesca Babiloni', 'Ioannis Marras', 'Filippos Kokkinos', 'Jiankang Deng', 'Grigorios Chrysos', 'Stefanos Zafeiriou'],
        projectPage: 'https://arxiv.org/abs/2107.02859',
        arxivLink: 'https://arxiv.org/abs/2107.02859',
        conference: 'ICCV, 2021',
        mediaLink: 'https://arxiv.org/abs/2107.02859',  // This link will be used when the media (image/video) is clicked
        // backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);'
    },
    {
        image: tothepoint,  // replace with your image path
        video: '',  // if you have a video, place its path here
        title: 'To The Point: Correspondence-driven monocular 3D category reconstruction',
        authors: ['Filippos Kokkinos', 'Iasonas Kokkinos'],
        projectPage: 'https://fkokkinos.github.io/to_the_point/',
        arxivLink: 'https://fkokkinos.github.io/to_the_point/resources/pdf/paper.pdf',
        conference: 'Neurips, 2021',
        mediaLink: 'https://fkokkinos.github.io/to_the_point/',  // This link will be used when the media (image/video) is clicked
        backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);'
    },
    {
        image: cvpr2021,  // replace with your image path
        video: '',  // if you have a video, place its path here
        title: 'Learning monocular 3D reconstruction of articulated categories from motion',
        authors: ['Filippos Kokkinos', 'Iasonas Kokkinos'],
        projectPage: 'https://fkokkinos.github.io/video_3d_reconstruction/',
        arxivLink: 'https://fkokkinos.github.io/to_the_point/resources/pdf/paper.pdf',
        conference: 'CVPR, 2021',
        mediaLink: 'https://fkokkinos.github.io/video_3d_reconstruction/',  // This link will be used when the media (image/video) is clicked
        backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);'
    },
    {
        image: microscopy,  // replace with your image path
        video: '',  // if you have a video, place its path here
        title: 'Microscopy Image Restoration with Deep Wiener-Kolmogorov filters',
        authors: ['Valeriya Pronina', 'Filippos Kokkinos', 'Dmitry V. Dylov', 'Stamatios Lefkimmiatis'],
        projectPage: 'https://arxiv.org/pdf/1911.10989',
        arxivLink: 'https://arxiv.org/pdf/1911.10989',
        conference: 'ECCV, 2020',
        mediaLink: 'https://arxiv.org/pdf/1911.10989',  // This link will be used when the media (image/video) is clicked
        // backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);'
    },
    {
        image: pafu,  // replace with your image path
        video: '',  // if you have a video, place its path here
        title: 'Pixel Adaptive Filtering Units',
        authors: ['Filippos Kokkinos', 'Ioannis Marras', 'Matteo Maggioni', 'Gregory Slabaugh', 'Stefanos Zafeiriou'],
        projectPage: 'https://arxiv.org/abs/1911.10581',
        arxivLink: 'https://arxiv.org/abs/1911.10581',
        conference: 'ArXiv, 2019',
        mediaLink: 'https://arxiv.org/abs/1911.10581',  // This link will be used when the media (image/video) is clicked
        // backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);'
    },
    {
        image: tip,  // replace with your image path
        video: '',  // if you have a video, place its path here
        title: 'Iterative joint image demosaicking and denoising using a residual denoising network',
        authors: ['Filippos Kokkinos', 'Stamatios Lefkimmiatis'],
        projectPage: 'https://ieeexplore.ieee.org/abstract/document/8668795',
        arxivLink: 'https://arxiv.org/pdf/1807.06403',
        conference: 'IEEE TIP, 2019',
        mediaLink: 'https://arxiv.org/pdf/1807.06403',  // This link will be used when the media (image/video) is clicked
        // backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);'
    },
    {
        image: cvpr_burst,  // replace with your image path
        video: '',  // if you have a video, place its path here
        title: 'Iterative residual cnns for burst photography applications',
        authors: ['Filippos Kokkinos', 'Stamatios Lefkimmiatis'],
        projectPage: 'https://fkokkinos.github.io/deep_burst/',
        arxivLink: 'https://arxiv.org/abs/1811.12197',
        conference: 'CVPR, 2019',
        mediaLink: 'https://fkokkinos.github.io/deep_burst/',  // This link will be used when the media (image/video) is clicked
        // backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);'
    },
    {
        image: tip,  // replace with your image path
        video: '',  // if you have a video, place its path here
        title: 'Iterative joint image demosaicking and denoising using a residual denoising network',
        authors: ['Filippos Kokkinos', 'Stamatios Lefkimmiatis'],
        projectPage: 'https://ieeexplore.ieee.org/abstract/document/8668795',
        arxivLink: 'https://arxiv.org/pdf/1807.06403',
        conference: 'ECCV, 2018',
        mediaLink: 'https://arxiv.org/pdf/1807.06403',  // This link will be used when the media (image/video) is clicked
        backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);'
    },
    {
        image: ntua,  // replace with your image path
        video: '',  // if you have a video, place its path here
        title: 'Βαθιά μηχανική μάθηση για κατηγοριοποίηση προτάσεων',
        authors: ['Filippos Kokkinos'],
        projectPage: 'https://dspace.lib.ntua.gr/xmlui/bitstream/handle/123456789/45506/bathia-mixaniki-mathisi.pdf',
        arxivLink: 'https://dspace.lib.ntua.gr/xmlui/bitstream/handle/123456789/45506/bathia-mixaniki-mathisi.pdf',
        conference: 'Diploma Thesis, 2017',
        mediaLink: 'https://dspace.lib.ntua.gr/xmlui/bitstream/handle/123456789/45506/bathia-mixaniki-mathisi.pdf',  // This link will be used when the media (image/video) is clicked
        // backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);'
    },
    {
        image: ntua,  // replace with your image path
        video: '',  // if you have a video, place its path here
        title: 'Tweester at SemEval-2017 Task 4: Fusion of Semantic-Affective and pairwise classification models for sentiment analysis in Twitter',
        authors: ['Athanasia Kolovou', 'Filippos Kokkinos', 'Aris Fergadis', 'Pinelopi Papalampidi', 'Elias Iosif', 'Nikolaos Malandrakis', 'Elisavet Palogiannidi', 'Harris Papageorgiou', 'Shrikanth Narayanan', 'Alexandros Potamianos'],
        projectPage: 'https://aclanthology.org/S17-2112/',
        arxivLink: 'https://aclanthology.org/S17-2112/',
        conference: 'ACL SemEval, 2017',
        mediaLink: 'https://aclanthology.org/S17-2112/',  // This link will be used when the media (image/video) is clicked
        // backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);'
    },
    {
        image: eacl,  // replace with your image path
        video: '',  // if you have a video, place its path here
        title: 'Structural Attention Neural Networks for improved sentiment analysis',
        authors: ['Filippos Kokkinos', 'Alexandros Potamianos'],
        projectPage: 'https://arxiv.org/pdf/1701.01811',
        arxivLink: 'https://arxiv.org/pdf/1701.01811',
        conference: 'EACL , 2017',
        mediaLink: 'https://arxiv.org/pdf/1701.01811',  // This link will be used when the media (image/video) is clicked
        backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);'
    },
    {
        image: ntua,  // replace with your image path
        video: '',  // if you have a video, place its path here
        title: 'Tweester at SemEval-2016 Task 4: Sentiment analysis in Twitter using semantic-affective model adaptation',
        authors: ['Elisavet Palogiannidi', 'Athanasia Kolovou', 'Fenia Christopoulou', 'Filippos Kokkinos', 'Elias Iosif', 'Nikolaos Malandrakis', 'Harris Papageorgiou', 'Shrikanth Narayanan', 'Alexandros Potamianos'],
        projectPage: 'https://aclanthology.org/S16-1023.pdf',
        arxivLink: 'https://aclanthology.org/S16-1023.pdf',
        conference: 'ACL SemEval, 2016',
        mediaLink: 'https://aclanthology.org/S16-1023.pdf',  // This link will be used when the media (image/video) is clicked
        // backgroundColor: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);'
    },
];

export default publications;
